.catalyserSvgLine-bg {
  background-image: url(../../asset/catalyserSvgLine.svg);
  /* width: 100%; */
  /* height: 100%; */
  object-fit: cover !important;
  background-repeat: no-repeat;
  background-position: unset;
  z-index: 100;
}

@media (max-width: 1024px) {
  .catalyserSvgLine-bg {
    background-image: url(../../asset/catalyseSvgLineRoteteMd.svg);
  }
}

@media (max-width: 724px) {
  .catalyserSvgLine-bg {
    background-image: url(../../asset/catalyseSvgLineRoteteSm.svg);
  }
}


/* 
.bg-gradient-radial {
  background: radial-gradient(65% 260% at 10.89% 91.56%, #222222 0%, rgba(34, 34, 34, 0.91) 52.8%, rgba(34, 34, 34, 0.84) 100%);
} */