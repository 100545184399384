


.modalFullScreenMobile {
  position: fixed;
  top: 0;
  right: 0;
  // bottom: 0;
  // left: 0;
  z-index: 99;

   overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  // background-color: rgba(255, 255, 255, 0.85);
  background-color: white;
  width: 100vw;
  height: 100vh;
  // height: 100%;
  // height: -webkit-fill-available;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;

  @media (min-width: 1537px) {
    position: absolute;
      top: -24px;
      right: 11px;
    // margin-left: auto;
    // margin-right: auto;


    margin-top: 24px;
    // margin: 0 auto;

        max-width: 944px;
              height: 366px;
      // max-height: 50vh;


    }
         @media (min-width: 1024px) and (max-width: 1536px) {
          //  margin-left: auto;
          //  margin-right: auto;
          position: absolute;
            top: -24px;
        right: 11px;


           margin-top: 24px;
           // margin: 0 auto;

           max-width: 944px;
           height: 366px;
          //  max-height: 50vh;


         }

  &.avoidEvents {
    pointer-events: none;
  }

  &.overlay {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &.showModal {
    visibility: visible;
    opacity: 1;
  }
}

.rhOverflow {
  overflow: scroll;
}
