.re-bg {
  background-image: url(../../asset/bg-remocapp.png);
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 100;
}

.bg-gradient-radial {
  background: radial-gradient(65% 260% at 10.89% 91.56%, #222222 0%, rgba(34, 34, 34, 0.91) 52.8%, rgba(34, 34, 34, 0.84) 100%);
}