.parent {
  background: #433CAE;
  width: 100vw;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parent[data-isOpen="true"] {
  width: 100vw;
  height: 100vh;
}



.child {
  width: 40px;
  height: 40px;
  background: #f107a3;
  /* border-radius: 50%; */
}

.bye {

  flex-direction: row;

  padding: 0px 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 44px;


  display: flex;
  align-items: center;
  text-align: center;



  color: #433CAE;


  width: 112px;
  height: 44px;


  background: #FFFFFF;
}