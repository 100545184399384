@tailwind base;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #F7F7F5
}

@tailwind components;
@tailwind utilities;

@layer components {
  /* .button {
    @apply flex justify-center items-center py-[14px] px-[24px] w-[152px] h-[48px] rounded-[19px] text-[14px] font-medium leading-[16px] text-white
  } */

  /* header */
  .contact {
    display: flex;

    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 167px;
    height: 56px;
    background: #433CAE;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 86px;
    text-align: center;
    color: #FFFFFF;
  }

  .menu-d {
    @apply not-italic;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 56px;
    height: 56px;

    background: #FFFFFF;
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.1);

  }

  .menu-t {
    @apply not-italic;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 56px;
    height: 56px;

    background: #FFFFFF;
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.1);
  }

  .menu-m {
    @apply not-italic;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 40px;
    height: 40px;

    background: #FFFFFF;
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.1);
  }




  /* hero */


  .heading-d {
    @apply not-italic;
    margin-top: 25vh;
    max-width: 1322px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 89px;
    line-height: 90px;
    text-align: center;
    color: #111827;

  }

  .heading-lg {
    @apply not-italic;
    font-style: normal;
    font-weight: 500;
    font-size: 66px;
    line-height: 76px;
    text-align: center;
    color: #111827;
  }

  .heading-t {
    @apply not-italic;
    /* margin-top: 182px; */
    max-width: 704px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 69px;
    text-align: center;
    color: #111827;
  }

  .heading-m {
    @apply not-italic;
    margin-top: 109px;
    max-width: 374px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: #111827;
  }

  .subheading-d {
    @apply not-italic;
    margin-top: 24px;
    max-width: 702px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 33px;
    text-align: center;
    color: #111827;

  }

  .subheading-t {
    @apply not-italic;
    margin-top: 32px;
    max-width: 548px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 27px;
    text-align: center;
    color: #111827;
  }

  .subheading-m {
    @apply not-italic;
    margin-top: 8px;
    max-width: 302px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #111827;
  }

  /* features */
  .feat-head-m {
    @apply not-italic;



    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;



    text-align: center;


    color: #111827;
  }

  .feat-head-t {
    @apply not-italic;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 55px;
    text-align: center;
    color: #111827;
  }

  .feat-head-d {
    @apply not-italic;



    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 74px;
    line-height: 90px;
    text-align: center;
    color: #111827;
  }

  .feat-title-m {
    @apply not-italic;

    padding: 16px 0px;

    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #111827;
  }

  .feat-title-t {
    @apply not-italic;
    padding: 16px 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    color: #111827;
  }

  .feat-title-d {
    @apply not-italic;
    padding: 16px 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
    color: #111827;
  }

  .feat-subtitle-m {
    @apply not-italic;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    color: #4B5563;
  }

  .feat-subtitle-t {
    @apply not-italic;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: #4B5563;
  }

  .feat-subtitle-d {
    @apply not-italic;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 33px;
    color: #4B5563;
  }

  /* products */

  .pro-head-m {
    @apply not-italic;
    max-width: 374px;
    margin-bottom: 24px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
  }

  .pro-head-t {
    @apply not-italic;
    max-width: 582px;
    margin-bottom: 32px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 63px;
    text-align: center;
    color: #FFFFFF;
  }

  .pro-head-d {
    @apply not-italic;
    max-width: 1184px;
    margin-bottom: 79px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 74px;
    line-height: 90px;
    text-align: center;
    color: #FFFFFF;
  }
--------
  .pro-head-m {
    @apply not-italic;
    max-width: 374px;
    margin-bottom: 24px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
  }

  .pro-head-t {
    @apply not-italic;
    max-width: 582px;
    margin-bottom: 32px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 63px;
    text-align: center;
    color: #FFFFFF;
  }

  /* .pro-head-d {
    @apply not-italic;
    max-width: 704px;
    margin-bottom: 79px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32.9px;
    text-align: center;
    color: #221E57;
  } */
---------
  /* wear */
  .wear-bg-m {
    @apply not-italic;
    background: radial-gradient(272.05% 116.37% at -135.26% 66.35%, rgba(174, 128, 215, 0.31) 1.43%, #733AA5 67%)
  }

  .wear-bg-t {
    @apply not-italic;
    background: radial-gradient(272.05% 116.37% at -135.26% 66.35%, rgba(174, 128, 215, 0.31) 1.43%, #733AA5 67%)
  }

  .wear-bg-d {
    @apply not-italic;
    background: radial-gradient(91.52% 218.41% at 38.18% 44.27%, rgba(174, 128, 215, 0.31) 1.43%, #733AA5 47.07%)
  }

  .wear-desc-m {
    @apply not-italic;



    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
  }

  .wear-desc-t {
    @apply not-italic;



    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }

  .wear-desc-d {
    @apply not-italic;


    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 33px;

    color: #FFFFFF;

  }

  .tag-m {


    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    height: 24px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 50px;
    display: flex;
    align-items: center;
    color: #000000;
    background: #FFFFFF;
  }

  .tag-t {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    height: 32px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 50px;
    color: #000000;
    background: #FFFFFF;
  }

  .tag-d {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    height: 32px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 50px;
    color: #000000;
    background: #FFFFFF;
  }

/* remocapp */
.remoc-bg {
  @apply not-italic;
  background: radial-gradient(65% 260% at 10.89% 91.56%, #222222 0%, rgba(34, 34, 34, 0.91) 52.8%, rgba(34, 34, 34, 0.84) 100%)
}

.remoc-bg-d {
  @apply w-full h-full not-italic;
  background: url(./asset/bg-remocapp.png);
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center right;

}


  /* exchange */
  .exh-bg {
    @apply not-italic;
    background: radial-gradient(81.35% 325.42% at 102.47% 103.49%, rgba(64, 166, 57, 0.77) 41.5%, #40A639 100%)
  }

  .exh-bg-d {
    @apply w-full h-full not-italic;

    background: url(./asset/exchange.svg);
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: bottom left
  }



  /* icert */

  .cert-bg {
    @apply not-italic;
    background: linear-gradient(128.07deg, #008BDF 14.6%, rgba(0, 139, 223, 0.7) 48.28%, rgba(0, 139, 223, 0.78) 82.89%);
  }



  .cert-bg-t {
    @apply not-italic;
    background: linear-gradient(128.07deg, #008BDF 14.6%, rgba(0, 139, 223, 0.7) 48.28%, rgba(0, 139, 223, 0.78) 82.89%);
  }


  /* .cert-bg-d {
    @apply w-full h-full not-italic;
    position: absolute;
    bottom: 0 !important;
    background: url(./asset/leaf.svg);
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: bottom left
  } */

  .cert-bg-g {
    @apply w-full h-full not-italic;
    position: absolute;
    bottom: 0 !important;
    background: url(./asset/gandom.svg);
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: bottom right
  }


  /* raadcoin */

  .raad-bg {
    @apply not-italic;
    background: radial-gradient(85.73% 85.73% at 51.07% 65.78%, rgba(61, 168, 174, 0.63) 13.35%, #3DA8AE 55.77%)
  }

  /* express */

  .express-bg {
    @apply not-italic;
    background: radial-gradient(50% 88.85% at 57.06% 54.82%, rgba(0, 111, 207, 0.56) 0%, #006FCF 79.17%)
  }



  /* bounty */

  .bounty-bg {
    @apply not-italic;
    background: linear-gradient(49.21deg, #292D39 27.99%, rgba(41, 45, 57, 0) 288.75%);
  }




  /* belz */


  .belz-tag-m {


    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    height: 24px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 50px;
    display: flex;
    align-items: center;
    color: #fff;
    background: #C8895B;
  }

  .belz-tag-t {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    height: 32px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 50px;
    color: #fff;
    background: #C8895B;
  }

  .belz-tag-d {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    height: 32px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 50px;
    color: #fff;
    background: #C8895B;
  }


  /* easy */


  .easy-bg {
    @apply not-italic;
    background: radial-gradient(49.02% 168.52% at 75.32% 55.21%, rgba(238, 92, 118, 0.49) 0%, #EE5C76 79.17%)
  }


  /* contact */

  .contact-m {
    @apply not-italic;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;

    text-align: center;

  }

  .contact-t {
    @apply not-italic;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 46px;

    text-align: center;



  }

  .contact-d {
    @apply not-italic;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 46px;

    text-align: center;

  }

  .contact-xl {
    @apply not-italic;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 74px;
    line-height: 90px;

    text-align: center;

  }

  .send-btn-m {
    @apply not-italic;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 86px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 8px;
    max-width: 353px;
    max-height: 56px;
    background: #433CAE;
  }

  .send-btn-t {
    @apply not-italic;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 86px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 8px;
    max-width: 464px;
    max-height: 56px;
    background: #433CAE;


  }

  .send-btn-d {
    @apply not-italic;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 86px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 8px;
    max-width: 464px;
    max-height: 56px;
    background: #433CAE;

  }

  .send-btn-xl {
    @apply not-italic;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 86px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 8px;

    max-width: 464px;
    max-height: 56px;
    background: #433CAE;
  }









  /* footer */
  .foot-txt {
    @apply not-italic;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
  }

  .foot-title {
    @apply not-italic;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #32B3E0;
  }

  .foot-link {
    @apply not-italic;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
  }



  /* Testimonials */

  .test-name-m {
    @apply not-italic;
    height: 32px;

    font-weight: 500;
    font-size: 22px;
    line-height: 44px;


    color: #111827;
  }

  .test-name-t {
    @apply not-italic;
    height: 69px;
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 44px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #111827;

  }

  .test-name-d {
    @apply not-italic;
    height: 56px;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: #111827;

  }

  .test-name-xl {
    @apply not-italic;
    height: 64px;
    font-style: normal;
    font-weight: 500;
    font-size: 49px;
    line-height: 62px;
    display: flex;
    align-items: center;
    color: #111827;
  }

  .test-desc-m {
    @apply not-italic;

    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;

    color: #4B5563;
  }

  .test-desc-t {
    @apply not-italic;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 33px;
    color: #4B5563;
  }

  .test-desc-d {
    @apply not-italic;

    height: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #4B5563;
  }

  .test-desc-xl {
    @apply not-italic;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #4B5563;
  }




}

.bg-height {
  height: calc(100vh - 128px);
}

.bg-height-lg {
  height: calc(100vh - 200px) !important;
}