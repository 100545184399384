.safe-T-Device-bg {
  background-image: url(../../asset/SafeTBg.svg);
  background-repeat: no-repeat;
  background-position: right;
}/* Vector */

@media (max-width: 724px) {
  .safe-T-Device-bg {
    background-position: top;
  }
}