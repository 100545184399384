// @import "../../assets/style/variables.scss";



 @media (max-width: 768px) {
.rhMenuWrapper {
padding: 40px 30px;}
 }
 @media (min-width: 768px) and (max-width: 1024px) {
.rhMenuWrapper {
padding: 40px 38px;}
}



.rhMenuWrapper {

  // display: flex;
  // flex-direction: row-reverse !important;
  // justify-content: space-between;

  .rhMenu {

a {
      text-decoration: none;
      display: inline-flex;
      margin-right: 34px;
      line-height: 28px;
      font-size: 16px;
      color: #111827;
      font-weight: 600;
      transition: all 0.2s ease-in;
      &:hover {
        color: #1c6db8;
      }
      &.activeClassName {
        color: #1c6db8;
      }
      &.title {

    font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
 display: flex;
      align-items: center;
 color: #433CAE;
      }
    }
    .contact {
      height: 56px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 16px 35px;
      // background: $primary;
      border-radius: 25px;
      box-sizing: border-box;
      color: #fff !important;
      &:hover {
        background: #21aada;
      }
    }
  }
  .header {
      display: flex;
        justify-content: center;
        align-items: center;
        align-items: center;
        // box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.1);


        width: 56px;
        height: 56px;

  }


        @media (min-width: 1537px) {

  .header {
      position: absolute;
      top: 0;
      right: 0;


    }



        }

        @media (min-width: 1024px) and (max-width: 1536px) {

    .header {
      position: absolute;
      top:0;
      right: 0;


    }

        }
  @media (max-width: 767px) {

    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    box-shadow: 0px 2px 48px -10px rgba(0, 0, 0, 0.1);
    a {

      display: flex !important;
      margin-right: initial !important;
    }
    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      align-items: center;
      box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.1);
      width: 40px;
      height: 40px;



    }
  }
}


 @media (min-width: 1024px) {

  .hide-in-desktop {

      display: none !important;
    }
  }
