/*
.title{

  color :red
} */
.subtitle {

  color: green
}

.smiely {
  max-width: 10px !important;
  max-height: 10px !important;


}