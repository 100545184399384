/* .shape-m{
height: 546px;
background: url("../../asset/bg-mob.webp");
background-repeat: no-repeat;
background-position: center;
background-size: cover;
} */
@media (max-width: 767px) {
  .test {


    height: calc(100vh - 48px);
  }

}



.hero-bg {

  position: absolute;

  bottom: 0;
}

.nonselect {

  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}