.wear-bg {
  background: url(../../asset/wear-bg.svg);
  width: auto;
  height: auto;
  object-fit: cover !important;
  background-repeat: no-repeat;
  background-position: center;



}